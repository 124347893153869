<template>
  <GroupInventory
    v-if="group"
    :current-group="group"
    :transport-options="transportations"
    :accommodation-options="accommodations"
  />
</template>

<script>
import GroupInventory from "./GroupInventory/GroupInventory.vue";

export default {
  components: { GroupInventory },
  computed: {
    group() {
      return this.$store.state["app-groups"]?.currentGroup;
    },
    transportations() {
      return this.$store.state["app-groups"]?.transportations;
    },
    accommodations() {
      return this.$store.state["app-groups"]?.accommodations;
    },
  },
};
</script>
